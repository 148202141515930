import { CCApiService } from './api-service';
import { Integration, SupportedUserRoles, Template, UIConfig } from './interfaces';

export class IntegrationsService {
  static ApiService: CCApiService;
  static integrationsApi: string;
  static integrationsRolesApi: string;
  static integrationsTemplatesApi: string;
  static apiBasePath: string;
  static customerId: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
    this.integrationsApi = `${config.apiBasePath}/customers/${this.customerId}/integrations`;
    this.integrationsRolesApi = `${config.apiBasePath}/customers/${this.customerId}/roles`;
    this.integrationsTemplatesApi = `${config.apiBasePath}/integrations/templates`;
  }

  static async getTemplates(): Promise<Template[]> {
    return this.ApiService.performFetch(this.integrationsTemplatesApi);
  }

  static async getIntegrations(appId?): Promise<Integration[]> {
    const url = appId
      ? this.integrationsApi.replace('/integrations', `/solutions/${appId}/integrations`)
      : this.integrationsApi;
    return this.ApiService.performFetch(url).then(result => result.content);
  }

  static async getSupportedRoles(): Promise<SupportedUserRoles> {
    return this.ApiService.performFetch(this.integrationsRolesApi).then(result => result);
  }
}
